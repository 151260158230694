define('pbr-client/components/testing-toolbar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        logoutUrl: '/logout',
        i18n: Ember.inject.service(),
        router: Ember.inject.service(),
        isAuthenticated: Ember.computed('authentication', function () {
            return this.get('authentication').isAuthenticated();
        }),
        init: function init() {
            this._super.apply(this, arguments);
            var allLanguages = ['id_ID', 'ms_MY', 'en_PH', 'zh_MY', 'en_SG', 'th_TH', 'zh_TW', 'vn_VN', 'en_US', 'en_GB', 'de_DE', 'es_ES', 'fr_FR', 'it_IT', 'en_PL', 'pl_PL', 'el_GR', 'ro_RO', 'hu_HU', 'cs_CZ', 'es_MX', 'es_AR', 'pt_BR', 'ru_RU', 'tr_TR', 'en_AU', 'ja_JP', 'ko_KR', 'ar_AE'];
            var supportedLanguages = [];
            for (var i = 0; i < allLanguages.length; i++) {
                supportedLanguages.push({ id: allLanguages[i], label: allLanguages[i] });
            }
            this.set('languages', supportedLanguages);
            this.set('language', this.get('i18n.locale'));
        },
        language: '',
        onLanguageChange: Ember.observer('language', function () {
            var language = this.get('language');
            this.set('i18n.locale', language);
            this.router.transitionTo('/all/' + language + '/bugReport/create');
        }),
        onLocaleChanged: Ember.observer('i18n.locale', function () {
            var locale = this.get('i18n.locale');
            this.set('language', locale);
        }),
        actions: {
            changeLocale: function changeLocale(language) {
                this.set('language', language);
            }
        }
    });
});