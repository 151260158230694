define('pbr-client/routes/locale', ['exports', 'lodash', 'pbr-client/config/environment'], function (exports, _lodash, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        /**
         * Loads translations for the provided locale (falls back to the next locale in the list if
         * they cannot be found
         * @param locales
         * @returns {*}
         */
        loadTranslations: function loadTranslations(locales) {
            var locale = locales.shift(),
                that = this;

            if (this.LOCALES_LOADED === undefined) {
                this.LOCALES_LOADED = {};
            }

            // All the english (en_BE, en_US, en_GB) are the same - skip the failures and just load en
            if (locale.indexOf('en_BE') === 0 || locale.indexOf('en_US') === 0) {
                locale = 'en';
            }

            this.LOCALES_LOADED[locale] = new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.getJSON('/locales/' + locale + '/translations.json').then(function (data) {
                    // add translations and set locale
                    that.set('i18n.locale', locale);
                    that.get('i18n').addTranslations(locale, data);
                    resolve(data);
                }, function (err) {
                    reject(err);
                });
            }).catch(function () {
                // Try the next
                if (locales.length) {
                    return that.loadTranslations(locales);
                } else {
                    return "Unable to load translations";
                }
            });

            return this.LOCALES_LOADED[locale];
        },
        beforeModel: function beforeModel(transition) {
            "use strict";

            var locale = transition.params.locale.locale,
                region = transition.params.locale.region;

            if (region === undefined) {
                region = 'riot';
            }

            Ember.$.removeCookie('pbr_region', { path: '/' });
            Ember.$.removeCookie('pbr_locale', { path: '/' });
            Ember.$.cookie('pbr_region', region, { path: '/' });
            Ember.$.cookie('pbr_locale', locale, { path: '/' });

            if (locale && locale.indexOf('-') > 0) {
                var localeParts = locale.split('-');
                locale = localeParts[0] + '_' + localeParts[1].toUpperCase();
            }

            if (locale !== transition.params.locale.locale || region !== transition.params.locale.region) {
                this.transitionTo('/' + region + '/' + locale + '/bugReport/create?' + Ember.$.param(transition.queryParams));
            }

            // validate region and locale
            if (_environment.default.APP.LOCALES[region] === undefined || !_lodash.default.isArray(_environment.default.APP.LOCALES[region]) || _environment.default.APP.LOCALES[region].indexOf(locale) === -1) {

                // If the region is valid, find the best default locale
                if (_lodash.default.isArray(_environment.default.APP.LOCALES[region])) {
                    var defaultLocale = _environment.default.APP.LOCALES[region][0];
                    if (locale && locale.indexOf('_')) {
                        var parts = locale.split('_');
                        // See if we can find a closer match - ex if its es_MX prefer es_AR
                        // Currently a bug where RiotBar sets cookies incorrectly for LAS to es_MX
                        _lodash.default.each(_environment.default.APP.LOCALES[region], function (locale) {
                            if (locale.indexOf(parts[0]) === 0) {
                                defaultLocale = locale;
                            }
                        });
                    }
                    locale = defaultLocale;
                } else {
                    region = _environment.default.APP.DEFAULT_REGION;
                    locale = _environment.default.APP.DEFAULT_LOCALE;
                }
                this.transitionTo('/' + region + '/' + locale + '/bugReport/create?' + Ember.$.param(transition.queryParams));
                return;
            }

            if (transition.targetName === 'locale.index') {
                this.transitionTo('locale.bugReport.create', { queryParams: transition.queryParams });
            }
        },
        /**
         * Return a promsie that resolves after RiotBar has been successfully loaded into the page
         * @returns {RSVP.Promise}
         */
        loadAuthentication: function loadAuthentication(params, queryParamString) {
            return this.get('authentication').initialize(params, queryParamString);
        },

        model: function model(params, transition) {
            var locale = params.locale,
                locales = [],
                that = this;

            if (locale) {
                locales.push(locale);
            }

            if (locale.indexOf('_') > 0) {
                // if its en-us, try en
                locales.push(params.locale.split('_')[0]);
            }

            // default locale
            locales.push('en_US');
            locales.push('en');
            locales = locales.uniq();

            var serializedQueryParams = transition.queryParams ? '?' + Ember.$.param(transition.queryParams) : '',
                authentication = this.get('authentication');

            return Ember.RSVP.all([this.loadAuthentication(params, serializedQueryParams), this.loadTranslations(locales.slice(0))]).then(function (data) {
                document.title = that.get('i18n').t('title');
                return {
                    queryParams: transition.queryParams,
                    // Save off query params incase we need them later for redirects
                    queryParamString: serializedQueryParams,
                    accountId: authentication.getAccountId(),
                    loggedIn: authentication.isAuthenticated(),
                    locale: params.locale,
                    is_rtl: params.locale === "ar_AE",
                    region: params.region,
                    translations: data[1],
                    knownIssues: data[2]
                };
            });
        }
    });
});